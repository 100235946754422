import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"

import Nav from "./navigation"
import Footer from "./footer"

const GlobalStyle = createGlobalStyle`
// @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,800');
  * {
    box-sizing: border-box;
    margin: 0;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    scroll-behavior: smooth;
  }

  body {
    overflow-x: hidden;
  }
`

const Frame = styled.div``

const Main = styled.main``

function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        {
          badge: file(name: { eq: "CTA Button" }) {
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={props => (
        <Frame>
          <GlobalStyle />
          <Nav />
          <Main>{children}</Main>
          <Footer />
        </Frame>
      )}
    />
  )
}

export default Layout
