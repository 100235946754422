import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Container from "@material-ui/core/Container"

const Foot = styled.footer`
  background: ${({ theme }) => theme.palette.navCol};
`
const SubFooter = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  background: ${({ theme }) => theme.palette.darkText};

  color: ${({ theme }) => theme.palette.lightText};
  font-size: 11px;

  p {
    padding: 10px;
  }
  div {
    padding: 10px;
  }
`

const SubFooterLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.lightText};
  padding: 24px;
`


const Flex = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
  @media only screen and (max-width: ${props => props.theme.breakpoints.small}) {
    // flex-direction: ${props => props.direction || "row"};
    flex-direction: column;

    div {
      margin: 18px 0;
    }
  }
`

const NavLink = styled(Link)`
  padding: 10px;
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 500;
  color: white;
  transition: text-shadow 300ms, transform 150ms;

  :hover {
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.7);
    transform: scale(1.03);
  }
  @media only screen and (max-width: ${props => props.theme.breakpoints.small}) {
    padding: 6px;
  }
`
var currentDate = new Date().getFullYear()

const Footer = () => (
  <Foot>
    <Container>
      <Flex direction="column">
        <NavLink to="/">STARTSEITE</NavLink>
        <NavLink to="/about">ÜBER UNS</NavLink>
        <NavLink to="/products">PRODUKT</NavLink>
        {/* <NavLink to="/services">SERVICES</NavLink> */}
        <NavLink to="/gallery">GALERIE</NavLink>
        <NavLink to="/contact">KONTAKT</NavLink>
      </Flex>
    </Container>
    <SubFooter>
      <p>Copyright &#64; {currentDate} Michael Schneider.</p>
      <div>
        <SubFooterLink to="/datenschutz">Datenschutz</SubFooterLink>
        <SubFooterLink to="/impressum">Impressum</SubFooterLink>
      </div>
    </SubFooter>
  </Foot>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
