import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import React, { useState } from "react"
import styled from "styled-components"
import { isMobile } from 'react-device-detect'
import MobileIcon from "../images/baseline-menu-24px.svg"
import DownArrow from "../images/angle-down-solid.svg"
import RightArrow from "../images/angle-right-solid.svg"

const Header = styled.header`
  background: ${({ theme }) => theme.palette.navCol};
  position: relative;
  z-index: 10;
  height: 63px;
  // box-shadow: 0 2px 52px 2px rgba(0, 0, 0, 1);

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100000;
  }
`

const Margins = styled.div`
  margin: 0 auto;
  max-width: 1060px;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) { 
        height: 100%;
      }
`

const Flex = styled.div`
  position: relative;
  display: flex;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
        height: 64px;
      }
`

const BlankSpace = styled.div`
  flex: 1;
`

const SubMenu = styled.div`
  position: absolute;
  top: 100%;
  display: ${props => (props.isSubMenuOpen ? "flex" : "none")};
  flex-direction: column;
  background: ${props => props.theme.palette.navCol};
  > a:hover {
    background: ${props => props.theme.palette.blueHightlight};
  }

  @media only screen and (max-width: ${props =>
    props.theme.breakpoints.small}) {
      top: 0;
      position: initial;
    }
  }
`

const SektionaltorMenu = styled.div`
  position: absolute;
  top: 0%;
  left: 100%;
  display: ${props => (props.isSektionaltorMenuOpen ? "flex" : "none")};
  flex-direction: column;
  background: ${props => props.theme.palette.navCol};
  > a:hover {
    background: ${props => props.theme.palette.blueHightlight};
  }

  @media only screen and (max-width: ${props =>
    props.theme.breakpoints.small}) {
      top: 0;
      position: initial;
    }
  }
`

const AntriebMenu = styled.div`
  position: absolute;
  top: 75%;
  left: 100%;
  display: ${props => (props.isAntriebMenuOpen ? "flex" : "none")};
  flex-direction: column;
  background: ${props => props.theme.palette.navCol};
  > a:hover {
    background: ${props => props.theme.palette.blueHightlight};
  }

  @media only screen and (max-width: ${props =>
    props.theme.breakpoints.small}) {
      top: 0;
      position: initial;
    }
  }
`

const SubMenuWrapper = styled.div`
  display: block;
  overflow: initial;
`

const DetailMenuWrapper = styled.div`
  display: block;
  overflow: initial;
`

const NavLink = styled(Link)`
  height: 64px;
  padding: 24px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 500;
  color: white;
  transition: text-shadow 300ms, transform 150ms;

  :hover {
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.7);
    transform: scale(1.03);
    background: ${props => props.theme.palette.blueHightlight};
  }
`


const Menu = styled(Flex)`
  @media only screen and (max-width: ${props =>
    props.theme.breakpoints.small}) {
    flex-direction: column;
    background: ${({ theme }) => theme.palette.navCol};
    position: fixed;
    right: ${props => (props.isMenuOpen ? "-230px" : 0)};
    top: 62px;
    width: 230px;
    transition: right 340ms;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 64px); //for overflow-y to work
    ::before {
      content: "";
      position: absolute;
      background: ${({ theme }) => theme.palette.navCol};
      top: -62px;
      left: 0;
      width 100%;
      height: calc(100vh - 64px);
      z-index: -100;
    }

    a {
      border-top: thin solid black;
      text-align: left;
    }
    ${NavLink}:hover{
      background: ${({ theme }) => theme.palette.navCol};
    }
  }
`

const SubMenuButton = styled.div`
  display: none;

  > img {
    height: 2.5rem;
    padding: 12px;
    margin: 5px;
  }
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    display: flex;
  }
`

const SubMenuNavLink = styled.div`
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: ${props =>
    props.theme.breakpoints.small}) {
        border-top: thin solid black;
    }
  }
  > a {
    border-top: none;
  }
`

const MobileMenuButton = styled(Flex)`
  display: none;
  padding: 10px 18px;
  align-items: center;
  justify-content: center;

  span {
    display: inline-block;
    color: white;
  }

  img {
    height: 24px;
    margin-left: 8px;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    display: flex;
  }
`

const MobileMenuOverlay = styled.div`
  display: ${props => (props.isMenuOpen ? "none" : "block")};
  position: fixed;
  background: rgba(0, 0, 0, 0.55);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -10;
`

const ImageWrapper = styled.div`
  background: white;
  position: absolute;
  padding: 24px 36px;
  margin-top: -2px;
  height: auto;
  width: 280px;
  border-radius: 1px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.6);

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin-left: 20px;
    padding: 14px 20px;
    height: 80px;
    width: 160px;
  }
`

const Products = styled(NavLink)`
 @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
        height: 78px;
      }
`

const activeStyles = {
  color: "black",
  fontWeight: "bold",
}

function Nav() {
  const [isMenuOpen, toggleMenu] = useState(true)
  const [isSubMenuOpen, toggleSubMenu] = useState(false)
  const [isSektionaltorMenuOpen, toggleSektionaltorMenu] = useState(false)
  const [isAntriebMenuOpen, toggleAntriebMenu] = useState(false)

  return (
    <StaticQuery
      query={graphql`
        {
          logo: file(name: { eq: "LOGOSchneiderTore" }) {
            name
            absolutePath
            childImageSharp {
              fluid {
                src
                aspectRatio
              }
            }
          }
        }
      `}
      render={props => (
        <Header>
          <Margins>
            <Flex>
              <ImageWrapper>
                <Link to="/">
                  <Img
                    fluid={props.logo.childImageSharp.fluid}
                    alt="Company Logo"
                  />
                </Link>
              </ImageWrapper>

              <BlankSpace />

              <Menu isMenuOpen={isMenuOpen}>
                <NavLink to="/" activeStyle={activeStyles}>
                  STARTSEITE
                </NavLink>
                <NavLink to="/about" activeStyle={activeStyles}>
                  ÜBER UNS
                </NavLink>
                <SubMenuWrapper
                  onMouseEnter={isMobile ? () => {return false;} : () => toggleSubMenu(true)}
                  onMouseLeave={isMobile ? () => {return false;} : () => toggleSubMenu(false)}
                >
                  <SubMenuNavLink>
                    <Products to="/products" activeStyle={activeStyles} >
                      PRODUKTE & SERVICE
                    </Products>
                    <SubMenuButton
                      onClick={() => toggleSubMenu(!isSubMenuOpen)}
                    >
                      <img
                        src={isSubMenuOpen ? DownArrow : RightArrow}
                        alt={isSubMenuOpen ? "down arrow" : "arrow right"}
                      />
                    </SubMenuButton>
                  </SubMenuNavLink>
                  <SubMenu isSubMenuOpen={isSubMenuOpen}>
                    <DetailMenuWrapper
                      onMouseEnter={isMobile ? () => {return false;} : () => toggleSektionaltorMenu(true)}
                      onMouseLeave={isMobile ? () => {return false;} : () => toggleSektionaltorMenu(false)}
                    >
                      <SubMenuNavLink>
                        <NavLink to="/sektionaltore" activeStyle={activeStyles}>
                          SEKTIONALTORE
                        </NavLink>
                        <SubMenuButton
                          onClick={() =>
                            toggleSektionaltorMenu(!isSektionaltorMenuOpen)
                          }
                        >
                          <img
                            src={
                              isSektionaltorMenuOpen ? DownArrow : RightArrow
                            }
                            alt={
                              isSektionaltorMenuOpen
                                ? "down arrow"
                                : "arrow right"
                            }
                          />
                        </SubMenuButton>
                      </SubMenuNavLink>
                      <SektionaltorMenu
                        isSektionaltorMenuOpen={isSektionaltorMenuOpen}
                      >
                        <NavLink
                          to="/deckensektionaltore"
                          activeStyle={activeStyles}
                        >
                          DECKENSEKTIONALTORE
                        </NavLink>
                        <NavLink
                          to="/seitensektionaltore"
                          activeStyle={activeStyles}
                        >
                          SEITENSEKTIONALTORE
                        </NavLink>
                      </SektionaltorMenu>
                    </DetailMenuWrapper>
                    <NavLink to="/industrietore" activeStyle={activeStyles}>
                      INDUSTRIETORE
                    </NavLink>
                    <NavLink to="/schwingtore" activeStyle={activeStyles}>
                      SCHWINGTORE
                    </NavLink>
                    <DetailMenuWrapper
                      onMouseEnter={isMobile ? () => {return false;} : () => toggleAntriebMenu(true)}
                      onMouseLeave={isMobile ? () => {return false;} : () => toggleAntriebMenu(false)}
                    >
                      <SubMenuNavLink>
                        <NavLink
                          to="/products#Antriebe"
                          activeStyle={activeStyles}
                          style={{ width: "100%" }}
                        >
                          ANTRIEBE
                        </NavLink>
                        <SubMenuButton
                          onClick={() => toggleAntriebMenu(!isAntriebMenuOpen)}
                        >
                          <img
                            src={isAntriebMenuOpen ? DownArrow : RightArrow}
                            alt={
                              isAntriebMenuOpen ? "down arrow" : "arrow right"
                            }
                          />
                        </SubMenuButton>
                      </SubMenuNavLink>
                      <AntriebMenu isAntriebMenuOpen={isAntriebMenuOpen}>
                        <NavLink
                          to="/garagentorantriebe"
                          activeStyle={activeStyles}
                        >
                          GARAGENTORANTRIEBE
                        </NavLink>
                        <NavLink
                          to="/drehtorantriebe"
                          activeStyle={activeStyles}
                        >
                          DREHTORANTRIEBE
                        </NavLink>
                        <NavLink
                          to="/schiebetorantriebe"
                          activeStyle={activeStyles}
                        >
                          SCHIEBETORANTRIEBE
                        </NavLink>
                      </AntriebMenu>
                    </DetailMenuWrapper>
                  </SubMenu>
                </SubMenuWrapper>
                {/* <NavLink to="/services" activeStyle={activeStyles}>
                  SERVICES
                </NavLink> */}
                <NavLink to="/gallery" activeStyle={activeStyles}>
                  GALERIE
                </NavLink>
                <NavLink to="/contact" activeStyle={activeStyles}>
                  KONTAKT
                </NavLink>
              </Menu>

              <MobileMenuButton onClick={() => toggleMenu(!isMenuOpen)}>
                <span>MENU</span>
                <img src={MobileIcon} alt="Mobile menu" />

                <MobileMenuOverlay
                  isMenuOpen={isMenuOpen}
                  onClick={() => toggleMenu(!isMenuOpen)}
                />
              </MobileMenuButton>
            </Flex>
          </Margins>
        </Header>
      )}
    />
  )
}
export default Nav
